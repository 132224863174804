<template>
  <div class="pt-lg-4 pt-xl-14 mt-6"  id="box_container" v-resize="onResize">
    <div>
      <v-row class="pa-0 d-flex align-center cl-negro flex-column">
          <v-col class="d-sm-flex pt-6 pb-0 py-sm-4 px-2 px-sm-11" cols="12" style=" overflow: hidden;">

            <v-col cols="12" class="px-2 px-sm-0 pt-16 d-flex justify-center align-center flex-column" style="position:relative">
              <v-card elevation="0" class="bd-radius-16">
                <v-carousel id="carrusel" style="width: 100% !important;" cycle interval="4000"  show-arrows-on-hover
                  :height="$vuetify.breakpoint.xs ? 300 : '' || $vuetify.breakpoint.lg ? 300 : '' || $vuetify.breakpoint.xl ? 460 : ''" hide-delimiters>
                
                  <v-carousel-item>
                    <div height="640px" style="overflow:hidden;">
                    <v-img src="../assets/images/cabecera.jpg" style="width: 100% !important;" :height="$vuetify.breakpoint.xs ? '300px' : '' || $vuetify.breakpoint.md ? '640px' : '' || $vuetify.breakpoint.xl ? 640 : 460" 
                      gradient="to right, rgba(0,20,35,.5), transparent" />
                    </div>
                    <v-card class="bd-radius-12 bounceInLeft animated pl-3 pl-sm-10 py-0 py-sm-4 mr-3"
                      :style="{ 'background': 'rgba(255,255,255,0)', 'max-width': '650px', 'position': 'absolute', 'z-index': ' 999', 'bottom': '10px', 'left': '10px' }">
                      <p :class="$vuetify.breakpoint.xs ? 'cl-light fw-6 fs-24 ln-1' : 'cl-light fw-6 fs-44 ln-1'">Sincronizando el <br />potencial de tu empresa</p>
                      <v-row class="pa-0 ma-0" style="max-height:70px;">
                        <v-col class="px-0 d-flex gap-15 align-center" cols="12">
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px;"><v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);padding:0px 10px;margin:0; height:30px"
                              text>Estrategia</v-btn>
                          </div>
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px;">
                            <v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);padding:0px 10px;margin:0; height:30px"
                              text>Actitud</v-btn>
                
                          </div>
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px; position:relative;">
                
                            <v-img src="../assets/images/hand.svg"
                              style="width:45px;position: absolute;top:-30px; right:-38px; transform: rotate(75deg);z-index: 999;" />
                            <v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);transform: rotate(-5deg);padding:0px 10px;margin:0; height:30px"
                              text>Herramientas</v-btn>
                
                          </div>
                
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-carousel-item>
                  
                  <v-carousel-item>
                    <v-img src="../assets/images/transporte.jpg" height="100%" 
                      gradient="to right, transparent, rgba(0,20,35,.5)" />
                    <v-card class="bd-radius-12 bounceInLeft animated pl-3 pl-sm-10 py-0 py-sm-4 mr-3"
                      :style="{ 'background': 'rgba(255,255,255,0)', 'max-width': '650px', 'position': 'absolute', 'z-index': ' 999', 'bottom': '10px', 'left': '10px' }">
                      <p :class="$vuetify.breakpoint.xs ? 'cl-light fw-6 fs-24 ln-1' : 'cl-light fw-6 fs-44 ln-1'">Sincronizando el <br />potencial de tu empresa</p>
                      <v-row class="pa-0 ma-0" style="max-height:70px;">
                        <v-col class="px-0 d-flex gap-15 align-center" cols="12">
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px;"><v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);padding:0px 10px;margin:0; height:30px"
                              text>Estrategia</v-btn>
                          </div>
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px;">
                            <v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);padding:0px 10px;margin:0; height:30px"
                              text>Actitud</v-btn>
                
                          </div>
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px; position:relative;">
                
                            <v-img src="../assets/images/hand.svg"
                              style="width:45px;position: absolute;top:-30px; right:-38px; transform: rotate(75deg);z-index: 999;" />
                            <v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);transform: rotate(-5deg);padding:0px 10px;margin:0; height:30px"
                              text>Herramientas</v-btn>
                
                          </div>
                
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-carousel-item>
                  
                  <v-carousel-item>
                    <v-img src="../assets/images/cabecera-taller.jpg" height="100%" 
                      gradient="to right,  rgba(0,20,35,.5), transparent" />
                    <v-card class="bd-radius-12 bounceInLeft animated pl-3 pl-sm-10 py-0 py-sm-4 mr-3"
                      :style="{ 'background': 'rgba(255,255,255,0)', 'max-width': '650px', 'position': 'absolute', 'z-index': ' 999', 'bottom': '10px', 'left': '10px' }">
                      <p :class="$vuetify.breakpoint.xs ? 'cl-light fw-6 fs-24 ln-1' : 'cl-light fw-6 fs-44 ln-1'">Sincronizando el <br />potencial de tu empresa</p>
                      <v-row class="pa-0 ma-0" style="max-height:70px;">
                        <v-col class="px-0 d-flex gap-15 align-center" cols="12">
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px;"><v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);padding:0px 10px;margin:0; height:30px"
                              text>Estrategia</v-btn>
                          </div>
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px;">
                            <v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);padding:0px 10px;margin:0; height:30px"
                              text>Actitud</v-btn>
                
                          </div>
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px; position:relative;">
                
                            <v-img src="../assets/images/hand.svg"
                              style="width:45px;position: absolute;top:-30px; right:-38px; transform: rotate(75deg);z-index: 999;" />
                            <v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);transform: rotate(-5deg);padding:0px 10px;margin:0; height:30px"
                              text>Herramientas</v-btn>
                
                          </div>
                
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-carousel-item>

                  <v-carousel-item>
                    <v-img src="../assets/images/image-industria.jpg" height="100%" 
                      gradient="to right,  rgba(0,20,35,.5), transparent" />
                    <v-card class="bd-radius-12 bounceInLeft animated pl-3 pl-sm-10 py-0 py-sm-4 mr-3"
                      :style="{ 'background': 'rgba(255,255,255,0)', 'max-width': '650px', 'position': 'absolute', 'z-index': ' 999', 'bottom': '10px', 'left': '10px' }">
                      <p :class="$vuetify.breakpoint.xs ? 'cl-light fw-6 fs-24 ln-1' : 'cl-light fw-6 fs-44 ln-1'">Sincronizando el <br />potencial de tu empresa</p>
                      <v-row class="pa-0 ma-0" style="max-height:70px;">
                        <v-col class="px-0 d-flex gap-15 align-center" cols="12">
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px;"><v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);padding:0px 10px;margin:0; height:30px"
                              text>Estrategia</v-btn>
                          </div>
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px;">
                            <v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);padding:0px 10px;margin:0; height:30px"
                              text>Actitud</v-btn>
                
                          </div>
                
                          <div style="min-width:60px; min-height:30px; border-radius: 5px; position:relative;">
                
                            <v-img src="../assets/images/hand.svg"
                              style="width:45px;position: absolute;top:-30px; right:-38px; transform: rotate(75deg);z-index: 999;" />
                            <v-btn
                              :class="$vuetify.breakpoint.xs ? 'shadow text-capitalize ff fw-5 fs-10' : 'shadow text-capitalize ff fw-5'"
                              color="#D0E6F5"
                              style="letter-spacing: 0;background:rgba(255, 255, 255, .15);color:#fff;border: 2px solid rgba(255, 255, 255, .1);transform: rotate(-5deg);padding:0px 10px;margin:0; height:30px"
                              text>Herramientas</v-btn>
                
                          </div>
                
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-carousel-item>

                </v-carousel>
              </v-card>
            </v-col>
            </v-col>




        <div id="transforma-tu-empresa"></div>
        <v-col cols="12" class="pt-lg-5 pt-xl-10 text-center d-flex align-center flex-column" :style="$vuetify.breakpoint.xl ? {'max-width':'1700px'}:{}">
          <h class="ff fw-7 cl-blue ln-1 mb-4 mb-sm-2" :style="$vuetify.breakpoint.xs ? {'font-size':'30px'}:{'font-size':'40px'}" >Transforma tu empresa en una entidad digital</h>

          <v-col class="py-0 px-2 cl-gris fs-16 fw-5 " cols="12" sm="8" xl="6" ><h>Capaz de interactuar en cualquier momento con su entorno, <span class="cl-cian">de
            forma segura y efectiva</span>, generando y procesando información estructurada y de valor.</h></v-col>
        </v-col>

        <v-col cols="12" class="pt-0" :style="$vuetify.breakpoint.xl ? {'max-width':'1700px'}:{}">

          <v-row class="d-flex justify-center">
            <v-col cols="12" xs="12" md="10" xl="9" class="pt-0 pt-sm-2 d-sm-flex justify-center" >


              <v-col cols="12" xs="12" sm="4">
                <v-card class="efecto-up-hover shadow-hover pa-4 bd-radius-16" elevation="0">
                  <v-img height="150px" class="bd-radius-8" src="../assets/images/estrategias-procesos.jpg" />
                  <p class="ff fw-6 cl-blue py-2 fs-18">Procesos</p>
                  
                <v-col class="pa-0 cl-gris fs-14">
                  <p class="pt-1"><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon> Ingeniería de procesos</p>
                  <p class="pt-1"><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon> <span class="cl-cian">Reingeniería</span> de procesos</p>
                  <p class="pt-1"><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon> Mitigación de riesgos operativos</p>
                  <p class="pt-1 cl-cian "><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon> Documentación de procesos</p>
                </v-col>
                <v-spacer/>
                <v-col class="d-flex justify-center"><v-btn class="text-capitalize cl-gris" text href="https://www.epcce.com/servicios/#estrategia"><a class="cl-gris" style="text-decoration:none; letter-spacing: 0;">Conocer Más</a></v-btn></v-col>
                </v-card>
              </v-col>

              
              <v-col cols="12" xs="12" sm="4">
                <v-card class="efecto-up-hover shadow-hover pa-4 bd-radius-16" elevation="0">
                  <v-img height="150px" class="bd-radius-8" src="../assets/images/image-06.jpg" />
                  <p  class="ff fw-6 cl-blue py-2 fs-18">Capacitación</p>
                  
                <v-col class="pa-0 cl-gris fs-14">
                  <p class="pt-1 cl-cian "><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon>Programas de capacitación</p>
                  <p class="pt-1"><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon>Cursos y talleres - Cursos digitales</p>
                  <p class="pt-1"><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon>Programa de <span class=" cl-cian">difusión cultural</span></p>
                  <p class="pt-1"><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon>Evaluaciones psicométricas</p>
                </v-col>
                <v-spacer/>
                <v-col class="d-flex justify-center"><v-btn class="text-capitalize cl-gris" text href="https://www.epcce.com/servicios/#actitud"><a class="cl-gris" style="text-decoration:none; letter-spacing: 0;">Conocer Más</a></v-btn></v-col>
                </v-card>
              </v-col>


              
              <v-col cols="12" xs="12" sm="4">
                <v-card class="efecto-up-hover shadow-hover pa-4 bd-radius-16" elevation="0">
                  <v-img height="150px" class="bd-radius-8" src="../assets/images/image-07.jpg" />
                  <p  class="ff fw-6 cl-blue py-2 fs-18">Herramientas</p>
                  
                <v-col class="pa-0 cl-gris fs-14">
                  <p class="pt-1"><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon>ERP <span class="fs-10">(Planificación de Recursos Empresariales)</span></p>
                  <p class="pt-1"><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon>LMS <span class="fs-10">(Gestión de las Relaciones con Clientes)</span></p>
                  <p class="pt-1"><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon>CRM <span class="fs-10">(Sistema de Gestión de la Formación)</span></p>
                  <p class="pt-1 cl-cian "><v-icon color="#0F85EF" style="transform:scale(80%)">mdi-checkbox-marked-circle-outline</v-icon>Desarrollo a la medida</p>
                </v-col>
                <v-spacer/>
                <v-col class="d-flex justify-center"><v-btn class="text-capitalize cl-gris" text href="https://www.epcce.com/servicios/#herramientas"><a class="cl-gris" style="text-decoration:none; letter-spacing: 0;">Conocer Más</a></v-btn></v-col>
                </v-card>
              </v-col>
              
            </v-col>
          </v-row>

          <v-row class="my-16 d-flex justify-center">

            <v-col cols="12" xs="12" md="10" class="pa-0 d-sm-flex justify-center align-center">

            <v-col cols="12" xs="12" sm="5" md="7" class="pa-0" id="convierte-tu-esfuerzo">
              <v-card-title class="pr-10 ff fw-7 cl-blue fs-24" style="word-break: normal;">
                Convertir el esfuerzo acumulado en un modelo de negocio óptimo.
              </v-card-title>
              <v-col class="cl-gris pr-md-8 pr-xl-16 text-justify">
                <v-col class="d-flex align-center"><v-icon color="#022A4E" style="transform:scale(.8)" class="mb-1 mr-0">mdi-chevron-right</v-icon><span class="cl-cian fw-5 fs-18">Estrategia</span></v-col>
                <p class="pl-4 pl-sm-10 fs-16"><ul><li>Agiliza, Eficienta y Optimiza tus procesos de negocio y de soporte, generando un <span class="cl-blue fw-5 ">mayor retorno sobre los recursos invertidos.</span> </li></ul></p>
                
                <v-col class="d-flex align-center"><v-icon color="#022A4E" style="transform:scale(.8)" class="mb-1 mr-0">mdi-chevron-right</v-icon><span class="cl-cian fw-5 fs-18">Actitud</span></v-col>
                <p class="pl-4 pl-sm-10 fs-16">
                <ul>
                  <li><span class="cl-blue fw-5 ">Conoce y desarrolla </span>las competencias empresariales que permitirán brindar <span class="cl-blue fw-5 ">soluciones a los retos de tu entorno de negocios.</span></li>
                  <li class="mt-2">Fortalece actitud y aptitud, forjando y estableciendo una cultura corporativa.</li>
                </ul>
                </p>
                
                <v-col class="d-flex align-center"><v-icon color="#022A4E" style="transform:scale(.8)" class="mb-1 mr-0">mdi-chevron-right</v-icon><span class="cl-cian fw-5 fs-18">Herramientas</span></v-col>
                <p class="pl-4 pl-sm-10 fs-16"><ul><li>Simplifica y agiliza las actividades, <span class="cl-blue fw-5 ">acelerando la productividad de tu negocio.</span></li></ul></p>
                
              </v-col>
            </v-col>

            <v-col cols="12" xs="12" sm="7" md="5" class="px-0">
              <v-row class="ma-0">
                
                <v-col class="pa-0">
                  <v-row class="ma-0 pr-sm-8">

                    
                    <v-col cols="12" xs="12" sm="6" class="pa-2 pr-sm-2 pb-2 bd-radius-16 d-flex align-end" style="position:relative;">
                    <v-col class="scroll-content fadeLeft d-flex justify-center shadow-hover pa-0 bd-radius-16" style="z-index:10;position:relative;overflow: hidden;">
                      <v-img class="zoom-hover" style="height:100%; z-index:1" src="../assets/images/image-collage-02.jpg"/>
                    </v-col>
                      <div style="position:absolute; width:150px; height:150px; background:#E9F8FC; top: -25px; left: -30px;z-index:0 ;border-radius: 150px;"></div>
                    </v-col>

                    <v-col cols="12" xs="12" sm="6" class="pa-2 pl-sm-2 pb-2 bd-radius-16 d-flex align-end" style="position:relative;overflow: hidden;">
                      <v-col class="scroll-content fadeRight shadow-hover pa-0 bd-radius-16" style="overflow: hidden;">
                        <v-img class="zoom-hover" style="height: 100%;" src="../assets/images/image-collage-01.jpg" />
                      </v-col>
                    </v-col>
                    
                  </v-row>
                </v-col>

                <v-col class="ma-0 pa-0">
                  <v-row class="ma-0 pr-0 pl-sm-8">
                    
                    <v-col cols="12" xs="12" sm="6" class="pa-2 pr-sm-2 pt-2  pb-2 pb-sm-0 bd-radius-16" style="overflow: hidden;">
                      <v-col class="scroll-content fadeTop shadow-hover pa-0 bd-radius-16" style="overflow: hidden;">
                        <v-img class="zoom-hover" style="min-height: 150px;" src="../assets/images/image-collage-03.jpg" />
                      </v-col>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" class="pa-2 pl-sm-2 pt-2 bd-radius-16" style="position:relative;">
                    <v-col class="scroll-content fadeBottom shadow-hover pa-0 bd-radius-16" style="z-index:10;position:relative;overflow: hidden;">
                      <v-img class="zoom-hover" style="min-height:150px; z-index:1" src="../assets/images/image-collage-04.jpg"/>
                    </v-col>
                      <div style="position:absolute; width:150px; height:150px; background:#E9F8FC; bottom: -50px; right: 40px;z-index:0 ;border-radius: 150px;"></div>
                    </v-col>
                  </v-row>

                </v-col>
                
              </v-row>
            </v-col>

            </v-col>

          </v-row>

        </v-col>
      </v-row>
    </div>



  </div>
</template>

<script>

export default {
  name: 'HomeView',
 
  
  data:() => ({

  windowSize: {
    x: 0,
    y: 0,
  },
  }),
  mounted () {
  this.onResize()
  },

  methods: {
  onResize () {
    this.windowSize = { x: window.innerWidth, y: window.innerHeight }
  },
  },
  }
</script>

<style>
.v-window__prev,
.v-window__next {
  background: transparent;
}
#carrusel{
  box-shadow: 0px 7px 10px rgba(50, 100, 200, .2);
}

#carrusel:hover .v-window__prev {
  margin-left: 15px !important
}

#carrusel:hover .v-window__next {
  margin-right: 15px !important
}

.v-expansion-panel::before,
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none
}
</style>