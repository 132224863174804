exports.install = function (Vue) {

Vue.prototype.colorNegro = '#282936'

Vue.prototype.rutaInicio = process.env.VUE_APP_RUTA_INICIAL
Vue.prototype.rutaNosotros = process.env.VUE_APP_RUTA_NOSOTROS
Vue.prototype.rutaServicios = process.env.VUE_APP_RUTA_SERVICIOS
Vue.prototype.rutaBlog = process.env.VUE_APP_RUTA_BLOG
Vue.prototype.rutaContacto = process.env.VUE_APP_RUTA_CONTACTO
Vue.prototype.rutaAvisoPrivacidad = process.env.VUE_APP_RUTA_AVISO_PRIVACIDAD
Vue.prototype.rutaSoiErp = process.env.VUE_APP_RUTA_SOI

Vue.prototype.rutaUrl = process.env.VUE_APP_RUTA_URL

Vue.prototype.nodeEnv = process.env.NODE_ENV


};