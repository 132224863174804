<template>
    <v-app>

        <container id="footer_nav" style="display:flex;width:100%;justify-items: center;">
            <v-row class="d-flex justify-center ma-0">
                <v-col cols="12" sm="12" class="py-10 px-0 ma-0 d-flex justify-center">
                    <v-row class="ma-0 px-16 d-flex align-start justify-center">

                        <v-col cols="12" sm="3" xl="2" style="color:#fff; " class="pa-0 d-flex align-start">
                                <v-icon color="#fff" class="mt-4">
                                    mdi-hand-pointing-right
                                    mdi-48px
                                    mdi-rotate-315
                                </v-icon>
                            <v-col>
                                <p style="color:#c1c4c8">Inicio</p>
                                <p>
                                    <a href="https://www.epcce.com/#transforma-tu-empresa" style="color:#849db0; text-decoration: none;"
                                        class="resaltar-link">Transforma tu empresa</a>
                                </p>
                                <p>
                                    <a href="https://www.epcce.com/#convierte-tu-esfuerzo" style="color:#849db0; text-decoration: none;"
                                        class="resaltar-link">Convierte el esfuerzo</a>
                                </p>
                            </v-col>
                        </v-col>

                        <v-col cols="12" sm="3" xl="2" style="color:#fff; " class="pa-0 d-flex align-start">
                            <v-icon color="#fff" class="mt-3">
                                mdi-account-group
                                mdi-48px
                            </v-icon>
                            <v-col>
                                <p style="color:#c1c4c8">Nosotros</p>
                                <p>
                                    <a href="https://www.epcce.com/nosotros/#somos" style="color:#849db0; text-decoration: none;"
                                        class="resaltar-link">Somos</a>
                                </p>
                                <p>
                                    <a href="https://www.epcce.com/nosotros/#nuestro-proposito" style="color:#849db0; text-decoration: none;"
                                        class="resaltar-link">Nuestro Propósito</a>
                                </p>
                                <p>
                                    <a href="https://www.epcce.com/nosotros/#nuestros-exitos" style="color:#849db0; text-decoration: none;"
                                        class="resaltar-link">Nuestros Éxitos</a>
                                </p>
                                <p>
                                    <a href="https://www.epcce.com/nosotros/#timeline" style="color:#849db0; text-decoration: none;"
                                        class="resaltar-link">Timeline</a>
                                </p>
                            </v-col>
                        </v-col>

                        <v-col cols="12" sm="3" xl="2" style="color:#fff; " class="pa-0 d-flex align-start">

                            <v-icon color="#fff" class="mt-5">
                                mdi-shield-search
                                mdi-36px
                            </v-icon>
                            <v-col>
                                <p style="color:#c1c4c8">Servicios</p>
                                <p>
                                    <a href="https://www.epcce.com/servicios/#estrategia" style="color:#849db0; text-decoration: none;"
                                        class="resaltar-link">Estrategia</a>
                                </p>
                                <p>
                                    <a href="https://www.epcce.com/servicios/#herramientas" style="color:#849db0; text-decoration: none;"
                                        class="resaltar-link">Herramientas</a>
                                </p>
                                <p>
                                    <a href="https://www.epcce.com/servicios/#actitud" style="color:#849db0; text-decoration: none;"
                                        class="resaltar-link">Actitud</a>
                                </p>
                            </v-col>
                        </v-col>

                        <v-col cols="12" sm="3" xl="2" style="color:#fff; " class="pa-0 d-flex justify-center align-start">
                            <v-icon color="#fff" class="mt-5">
                                mdi-comment-multiple
                                mdi-36px
                            </v-icon>
                            <v-col>
                                <p style="color:#c1c4c8">Contacto</p>
                                <!-- <p>
                                    <a href="https://'www.epcce.com/contacto/#preguntas-frecuentes'"
                                        style="color:#849db0; text-decoration: none;" class="resaltar-link">Preguntas
                                        Frecuentes</a>
                                </p> -->
                                <p>
                                    <a href="https://www.epcce.com/contacto/#contactanos" style="color:#849db0; text-decoration: none;"
                                        class="resaltar-link">Contáctanos</a>
                                </p>
                            </v-col>
                        </v-col>

                        <!-- <v-col cols="2" style="color:#fff; gap:20px" class="pa-0 d-flex flex-column flex-sm-row align-sm-center">
                            <v-btn text style="color:#fff;text-transform: capitalize; letter-spacing: 0; border-bottom: 1px solid #5b7589;border-radius: 0;">Descubrir más</v-btn>
                        </v-col> -->
                    </v-row>
                </v-col>
                <v-col cols="12" class="copyright px-sm-16 d-sm-flex justify-center">
                    <v-col cols="12" sm="12" xl="10" class="pa-0 d-sm-flex">
                        <v-col cols="12" sm="6" class="text-center text-sm-left">
                            <h>Copyright © 2021-2022 EPC Clínica Empresarial Todos los derechos reservados.</h>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="6" class="d-flex justify-end">
                            <a class="resaltar-link" style="color:#849db0; text-decoration: none;" href="https://www.epcce.com/aviso_de_privacidad">Aviso de privacidad</a>
                        </v-col>
                    </v-col>
                </v-col>
            </v-row>
        </container>

    </v-app>
</template>

<script>
export default {
    name: 'FooterNav',
    data() {

    },
}
</script>