<template>
  <div class="pa-0">
      <MenuNavegation/>
      <FooterNav/>
  </div>
</template>

<script>
import MenuNavegation from './components/MenuNavegation.vue'
import FooterNav from './components/FooterNav.vue'

export default {
  components:{
    MenuNavegation,
    FooterNav
  },
}
</script>