import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import '../public/css/style.css'
import '../public/css/estilosGenerales.css'
import variablesGlobales from "./variablesGlobales";
import '../src/funcionesGlobales'

Vue.config.productionTip = false;
Vue.use (variablesGlobales); 

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
