import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/InicioView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: process.env.VUE_APP_RUTA_INICIAL,
    name: 'inicio',
    component: HomeView
  },
  {
    path: process.env.VUE_APP_RUTA_NOSOTROS,
    name: 'nosotros',
    component: () => import(/* webpackChunkName: "about" */ '../views/NosotrosView.vue')
  },
  {
    path: process.env.VUE_APP_RUTA_SERVICIOS,
    name: 'servicios',
    component: () => import(/* webpackChunkName: "about" */ '../views/ServiciosView.vue')
  },
  {
    path: process.env.VUE_APP_RUTA_SOI,
    name: 'soi_erp',
    component: () => import(/* webpackChunkName: "about" */ '../views/SoiErp_view.vue')
  },
  {
    path: process.env.VUE_APP_RUTA_BLOG,
    name: 'blog',
    component: () => import(/* webpackChunkName: "about" */ '../views/BlogView.vue')
  },
  {
    path: process.env.VUE_APP_RUTA_CONTACTO,
    name: 'contacto',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactoView.vue')
  },
  {
    path: process.env.VUE_APP_RUTA_AVISO_PRIVACIDAD,
    name: 'aviso_de_privacidad',
    component: () => import(/* webpackChunkName: "about" */ '../views/AvisoDePrivacidad.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
      return { x: 0, y: 0 }
  }
  
})

export default router
