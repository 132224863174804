<template>
    <v-app>
        <div id="nav_box">            
            <v-row class="d-flex justify-center">
                <v-col cols="11" class="pa-0 ma-0">
                    <v-row class="ma-0 pa-0 px-md-4 py-4 py-lg-2 py-xl-4 d-flex align-center">

                        <v-col cols="2" class="pa-0 d-flex justify-center">
                            <router-link class="pa-0 pl-16 pl-sm-0 pt-2 ma-0" :to="this.rutaInicio"><v-img  src="../assets/images/EPCLOGO.png"  width="100px"/></router-link>
                        </v-col>

                        <v-col cols="10" class="d-flex justify-end pa-0">
                            <v-col cols="10" class="d-flex justify-end fw-5 align-center"
                                :style="{'gap' : '5px'}" v-if="$vuetify.breakpoint.xs === false">
                            
                                <router-link :to=" item.path " v-for="item in items" :key="item.title" link>{{ item.title }}</router-link>
                                <router-link :to="this.rutaSoiErp">
                                    <v-img src="../assets/images/logo_soi.png"  width="50px" />
                                </router-link>
                                <v-divider vertical class="pl-1"></v-divider>
                                
                            </v-col>
                        
                        <v-col cols="10" xs="10" sm="2" class="pa-0 ma-0 d-flex justify-end justify-sm-start align-center">
                            <a href="https://www.facebook.com/EficientaProcesosYControlesClinicaEmpresarial?mibextid=ZbWKwL" target="_blank" class="pt-1">
                                <img src="../assets/images/facebook.svg" width="28px"/>
                            </a>
                            <a href="https://www.instagram.com/epc_clinicaempresarial/" target="_blank" class="pt-1">
                                <img src="../assets/images/instagram.svg" width="28px"/>
                            </a>
                            <a href="https://www.linkedin.com/company/epc-clinica-empresarial/" target="_blank" class="pt-1">
                                <img src="../assets/images/linkedin.svg" width="32px"/>
                            </a>
                            <v-btn v-if="$vuetify.breakpoint.xs" class="pa-0 ml-1" text style="min-width:40px !important" @click.stop="drawer = !drawer">
                                <svg id="Layer_2" style="enable-background:new 0 0 32 32;width:25px;" version="1.1" viewBox="0 0 32 32" xml:space="preserve"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g>
                                        <path fill="#0F85EF"
                                            d="M2,14.34998h10.84998c0.83002,0,1.5-0.66998,1.5-1.5V2c0-0.83002-0.66998-1.5-1.5-1.5H2C1.16998,0.5,0.5,1.16998,0.5,2   v10.84998C0.5,13.67999,1.16998,14.34998,2,14.34998z" />
                                        <path fill="#57afff"
                                            d="M31.5,2c0-0.83002-0.66998-1.5-1.5-1.5H19.15002c-0.83002,0-1.5,0.66998-1.5,1.5v10.84998c0,0.83002,0.66998,1.5,1.5,1.5   H30c0.83002,0,1.5-0.66998,1.5-1.5V2z" />
                                        <path fill="#8fcaff"
                                            d="M2,31.5h10.84998c0.83002,0,1.5-0.67004,1.5-1.5V19.14996c0-0.82996-0.66998-1.5-1.5-1.5H2c-0.83002,0-1.5,0.67004-1.5,1.5   V30C0.5,30.82996,1.16998,31.5,2,31.5z" />
                                        <path fill="#046fcf"
                                            d="M24.57001,17.64996c-3.82001,0-6.91998,3.10004-6.91998,6.92004S20.75,31.5,24.57001,31.5S31.5,28.39001,31.5,24.57001   S28.39001,17.64996,24.57001,17.64996z" />
                                    </g>
                                </svg></v-btn>
                        </v-col>
                        </v-col>

                    </v-row>
                </v-col>
            </v-row>



        </div>

                <v-sheet>
            
                    <v-navigation-drawer v-model="drawer" fixed temporary style="z-index:99999;width: 78%;">
                    
                        <v-col cols="12" class="shadow d-flex justify-center">
                            <router-link :to="this.rutaInicio"><v-img src="../assets/images/EPCLOGO.png" :width="100" /></router-link>
                        </v-col>
                    
                        <v-col class="d-flex flex-column">
                            <router-link class="my-1" style="text-decoration:none !important; padding:10px 20px;" :to=" item.path "
                                v-for="item in items" :key="item.title" link>
                                <!-- <v-icon>{{ item.icon }}</v-icon> --> {{ item.title }}</router-link>
                        </v-col>
                        <v-col class="mt-n1 pt-0 pl-8">
                            <router-link :to="this.rutaSoiErp">
                                <v-img src="../assets/images/logo_soi.png" style="height:auto;width: 60px;" />
                            </router-link>
                        </v-col>
                    </v-navigation-drawer>
                </v-sheet>

        <router-view />
    </v-app>
</template>
<script>
export default {
    name: 'MenuNavegation',
    data: () =>({
        
        drawer: false,
        items: [
            { path: process.env.VUE_APP_RUTA_INICIAL, title: 'Inicio', icon: 'mdi-view-dashboard' },
            { path: process.env.VUE_APP_RUTA_NOSOTROS, title: 'Nosotros', icon: 'mdi-forum' },
            { path: process.env.VUE_APP_RUTA_SERVICIOS, title: 'Servicios', icon: 'mdi-view-dashboard' },
            // { path: process.env.VUE_APP_RUTA_BLOG, title: 'Blog', icon: 'mdi-forum' },
            { path: process.env.VUE_APP_RUTA_CONTACTO, title: 'Contacto', icon: 'mdi-view-dashboard' },
            // { path: process.env.VUE_APP_RUTA_AVISO_PRIVACIDAD, title: 'Aviso', icon: 'mdi-view-dashboard' },

        ],
    }),
    created(){
        document.createElement('img')
    }
}
</script>
